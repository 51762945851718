.block-title {
  font-weight: 700;
  font-size: 18px;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
  min-inline-size: initial;
}

.field-image {
  .upload-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-light {
      margin-bottom: 0;
    }
  }

  .button-group {
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;

    > * + * {
      margin-left: 10px;
    }
  }

  .upload-button {
    margin: 0;
  }
}

.formik {
  &.titled {
    padding-top: 20px;
  }

  .form-header-title {
    margin-top: 0;
    font-size: 25px;
    font-family: Roboto, sans-serif;
  }

  .form-title {
    padding: 16px 0;
    font-weight: 700;
    font-size: 22px;
    font-family: Roboto, sans-serif;
    line-height: 1;
  }

  .block-header {
    padding: 16px 0;

    .page-name {
      overflow: hidden;
      font-size: 20px;
      text-align: left;
      text-overflow: ellipsis;
    }

    .page-description {
      &,
      & p {
        text-align: left;
        word-wrap: break-word;
      }
    }
  }

  span[role='menu'] {
    display: block;
    margin-top: 5px;
  }

  span.select-sub-label {
    margin-top: 18px;
    font-size: 12px;
  }

  .ris .ris-input {
    height: 34px;
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    border-color: #b1b1b1;

    &:disabled {
      background-color: #ebebe4;
    }

    &::-webkit-input-placeholder {
      font-style: normal;
    }

    &::-moz-placeholder {
      font-style: normal;
    }

    &:-ms-input-placeholder {
      font-style: normal;
    }

    &:-moz-placeholder {
      font-style: normal;
    }
  }

  .ris-options .ris-option {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rc-select-selection-selected-value {
    padding-right: 20px;
  }

  .new-name-will-be-created {
    padding: 5px;
    color: #555050;
    font-style: italic;
    word-wrap: break-word;
    border-radius: 5px;
  }
}

.form-wizard {
  &_row {
    display: flex;
    align-items: flex-start;

    .form-wizard-navigation {
      flex: 0 0 250px;
      margin-right: 40px;
      overflow: hidden;
      border-style: solid;
      border-width: 1px;
      border-radius: 20px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.13);
    }

    .block-header {
      padding: 16px;
      text-align: center;
      word-wrap: break-word;
      word-break: break-word;
    }

    .description {
      padding: 0 16px;
      text-align: center;
    }

    .form-wizard-body {
      flex: 1 1 100%;
      min-width: 0%;
      padding: 30px;
      border-style: solid;
      border-width: 1px;
      border-radius: 20px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.13);

      .block-header {
        padding: 0;
      }

      .page-name {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
  }

  &_headless {
    .form-wizard-navigation {
      display: none;
    }

    .block-header {
      display: none;
    }
  }
}
