.initial-loader {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #191a1a;

  h1 {
    display: flex;
    margin: 0;
    margin-top: -3.78em;
    padding: 0;
    color: #a3e1f0;
    font-weight: 100;
    font-size: 1.1em;
    font-family: ‘Arial Narrow’, sans-serif;
  }

  span {
    position: relative;
    top: 3.78em;
    display: inline-block;
    margin: 0 2px;
    text-transform: uppercase;
    transform: rotateX(0deg);
    opacity: 1;
  }

  .let1 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.2s;
  }

  .let2 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.3s;
  }

  .let3 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.4s;
  }

  .let4 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.5s;
  }

  .let5 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.6s;
  }

  .let6 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.7s;
  }

  .let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.8s;
  }

  @keyframes drop {
    0% {
      transform: rotateX(-90deg);
      opacity: 0;
    }

    10% {
      top: 0.63em;
      opacity: 0.5;
    }

    20% {
      top: 3.78em;
      transform: rotateX(-360deg);
      opacity: 1;
    }

    80% {
      top: 3.78em;
      transform: rotateX(-360deg);
      opacity: 1;
    }

    90% {
      top: 0.63em;
      opacity: 0.5;
    }

    100% {
      top: 6.94em;
      opacity: 0;
    }
  }
}
