html,
body,
#root {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0 0 11px;
  line-height: 1.6;
}
