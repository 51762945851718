.drop-placeholder-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: opacity 1s ease-out;

  &.darken {
    background: rgba(90, 96, 101, 0.123);
    border: 3px solid #bbdce0;
  }
}

.drop-placeholder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.drop-placeholder-text {
  max-width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.preview-max-size {
  margin-top: 5px;
}

.tiff-cannot-be-viewed-placeholder {
  text-align: center;
}
