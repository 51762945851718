.debug-changed-fields-list {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-height: 400px;
  padding: 5px;
  overflow: auto;
  background: #f0e68c;
}

.debug-normalized-rules-list {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  max-height: 400px;
  padding: 5px;
  overflow: auto;
  background: #f0e68c;
}

.debug-changed-fields-title {
  padding-top: 10px;
  padding-bottom: 20px;
  color: #ff7f50;
  font-size: 16px;
  text-align: center;
}
