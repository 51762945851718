@mixin font-face($font-name, $font-path, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-weight: $font-weight;
    font-family: $font-name;
    font-style: $font-style;
    src: url('#{$font-path}.woff2');
  }
}

@include font-face('Roboto', '../fonts/Roboto-Regular', 400);
@include font-face('Roboto', '../fonts/Roboto-Bold', 700);

@include font-face('Kinto', '../fonts/KintoSans-Regular', 400);
@include font-face('Kinto', '../fonts/KintoSans-Bold', 700);
