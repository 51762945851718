.modal-content {
  flex: 1 0 auto;
  align-content: flex-start;
  min-height: 310px;

  .input-block:not(.horizontal-multiply-select) .rc-select {
    .rc-select-selection--single {
      height: 34px;
    }

    .rc-select-selection--single,
    .rc-select-selection--multiple {
      .rc-select-selection__rendered {
        height: 32px;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        line-height: 32px;
      }

      .rc-select-selection-selected-value {
        position: static;
        padding-left: 8px;
        font-size: 14px;
        font-style: normal;
      }

      .rc-select-search--inline .rc-select-search__field {
        height: 32px;
        vertical-align: middle;
      }
    }

    .rc-select-selection--multiple {
      .rc-select-selection__placeholder {
        position: static;
        height: 32px;
        padding-left: 8px;
        font-size: 14px;
        font-style: normal;
        line-height: 32px;
      }

      .rc-select-search--inline .rc-select-search__field {
        margin-left: 8px;
        padding: 0;
      }
    }

    .rc-select-arrow {
      height: 26px;
    }

    .rc-select-selection {
      border-color: #b1b1b1;
    }

    .rc-select-selection__placeholder {
      color: #aaa;
      font-size: 14px;
      font-style: normal;
    }
  }

  .rc-select {
    .rc-select-selection--multiple .rc-select-selection__rendered {
      margin-left: 0;

      ul {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 1px;
        overflow: auto;
      }

      .rc-select-selection__choice {
        flex: 0 0 auto;
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 4px 9px;
      }
    }
  }
}

.application-access-modal {
  .rc-select-selection__rendered {
    min-height: 32px;
  }

  .rc-select-selection__placeholder {
    padding-left: 8px;
    font-size: 14px;
    font-style: normal;
    line-height: 32px;
  }

  .horizontal-multiply-select .rc-select .rc-select-search--inline .rc-select-search__field {
    height: 32px;
    margin-left: 8px;
  }
}

.PluginRepository-modal {
  h3 {
    padding-right: 0px;
  }

  ul {
    margin: 17px 0 25px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 15px;
      line-height: 1.4;
      text-align: left;

      &:before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 4px;
        height: 4px;
        background: #518c98;
        border-radius: 50%;
        content: '';
      }
    }
  }
}

.markdown-content-wrapper {
  h4 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    font-family: Roboto, sans-serif;
    line-height: 30px;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.4;
  }

  ul {
    margin: 10px 0;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 15px;
      line-height: 1.4;

      &:before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        content: '';
      }
    }
  }
}

.tab-preview {
  p {
    line-height: 1.5;
  }
}

.modal {
  [class^='Overlay'] {
    z-index: 1000;
  }
}
