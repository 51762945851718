.app-img-icon,
.app-svg-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  border-radius: 4px;

  .business-processes-image & {
    height: 100%;
  }

  .form-wizard-navigation & {
    height: 110px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40px;
    height: 40px;

    .business-processes-image & {
      width: 100%;
      height: 100%;
    }

    .form-wizard-navigation & {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.app-img-content {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
